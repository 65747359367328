import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(({ palette }) => ({
  inputBox: {
    flexBasis: '50%',
    whiteSpace: 'nowrap',
    paddingRight: 24,
    textOverflow: 'ellipsis',
    paddingBottom: 24,
  },
  btnSubmit: {
    marginLeft: 15,
    marginTop: 19,
  },
  usernameInput: {
    width: 300,
    marginRight: 20,
    marginTop: 15,
  },
  doubleContent: {
    backgroundColor: palette.background.paper,
    padding: 15,
    marginTop: 5,
    '& h6': {
      fontSize: 16,
      color: '#929292',
    },
    '& button': {
      marginLeft: 0,
      marginTop: 34,
    },
  },
  singleContent: {
    backgroundColor: palette.background.paper,
    padding: 15,
    marginTop: 5,
    display: 'flex',
    flexWrap: 'wrap',
    '& h6': {
      fontSize: 16,
      color: '#929292',
    },
    '& button': {
      marginLeft: 0,
      marginTop: 10,
    },
  },
  overwriteBox: {
    '& span.MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 600,
    }
  },
  userWarningMessages: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #1976d2',
    borderRadius: 5,
    padding: '6px 10px',
    background: '#E6EBF0',
  },
  textWarningMessages: {
    margin: '1px 30px 0 10px',
    fontSize: 13,
    color: palette.grey.A700,
  },
  cardRowBtn: {
    display: 'flex',
    justifyContent: 'end',
  },
  btnContainer: {
    display: 'flex',
  },
  btnAlign: {
    backgroundColor: '#1976D2',
    textTransform: 'capitalize',
    height: 40,
    width: 150,
    marginLeft: 50,
  },
  btnSection: {
    '& button': {
      border: 0,
      paddingLeft: 0,
      color: '#1976d2',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      textTransform: 'capitalize',
      '&:hover': {
        color: '#1976d2',
        backgroundColor: 'transparent !important',
        textDecoration: 'underline',
        boxShadow: 'none',
      },
    },
  },
}));
