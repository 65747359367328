import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  headerWrapper: {
    color: theme.palette.grey.A700,  
    '& h3': {
      fontWeight: 600,
    },   
    '& svg': {
      display: 'none',
    }, 
  },
  errorText: {
    color: theme.palette.error.main,
  },
  bluText: {
    color: theme.palette.basicPalette.primary,
    marginRight: 60,
  },
  btnContainer:{
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: 50,
    '& button': {
      backgroundColor: theme.palette.basicPalette.primary,
      minWidth: 150,
      height: 40,
      '&:hover': {
        backgroundColor: theme.palette.basicPalette.primaryLight,
      },
    },
  },
}));
