import React, { FC, ReactNode, useEffect } from 'react';
import {
  CustomAgGridReact,
  AgGridActions,
  AgGridGroupHeader,
  useGridState,
  useAgGrid
} from '@wings-shared/custom-ag-grid';
import { LogContextChangeModel, LogContextModel } from '../../../Shared/Models/LogContext.model';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { GridOptions, ColDef } from 'ag-grid-community';
import { Button } from '@material-ui/core';
import { styles } from './LogContext.style';
import { inject, observer } from 'mobx-react';

interface Props {
  context: LogContextModel
}

const LogContextDetail: FC<Props> = ({ ...props }: Props) => {

  const classes: Record<string, string> = styles();
  const gridState = useGridState();
  const agGrid = useAgGrid<'', LogContextChangeModel>([], gridState);

  useEffect(() => {
    gridState.setGridData(props.context.changes);
  }, []);

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Property',
      field: 'property'
    },
    {
      headerName: 'Before',
      field: 'oldValue'
    },
    {
      headerName: 'After',
      field: 'newValue'
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    return {
      ...agGrid.gridOptionsBase({
        context: {},
        columnDefs: columnDefs,
        isEditable: false
      }),
      isExternalFilterPresent: () => false,
      frameworkComponents: {
        actionRenderer: AgGridActions,
        customHeader: AgGridGroupHeader,
      },
      pagination: false,
    };
  }

  /* istanbul ignore next */
  const dialogContent = (): ReactNode => {
    const exceptionDetail = props.context.exceptionDetails;
    return <>
      {
        exceptionDetail && <div><b>Exception Detail : </b> {exceptionDetail}</div>
      }
      {
        !exceptionDetail && <CustomAgGridReact
          gridOptions={gridOptions()}
          rowData={gridState.data}
          hidePagination={true}
        />
      }
      <Button
        color="primary"
        variant="contained"
        size="small"
        className={classes.btnAlign}
        onClick={() => ModalStore.close()}
      >
        Close
      </Button>
    </>
  }

  return (
    <Dialog
      title='Log Details'
      classes={{
        dialogWrapper: classes.modalRoot,
        header: classes.headerWrapper,
      }}
      open={true}
      onClose={() => ModalStore.close()}
      dialogContent={dialogContent}
    />
  )
}

export default inject('logStore')(observer(LogContextDetail));

