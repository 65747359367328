import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const styles = makeStyles(({ palette, spacing }: Theme) => ({
  mainContainer: {
    display: 'flex',
  },
  mainroot: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  mainContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    width: '310px',
    minWidth: '310px',
    paddingLeft: '15px',
  },
  advancedIcon: {
    margin: '3px 0 0 10px',
    width: '24px',
    cursor: 'pointer',
  },
  searchInputControl: {
    width: 'calc(100% - 190px)',
    '& ~ div': {
      minWidth: '185px',
      margin: '0 !important',
    },
  },
  rootControl: {
    flexDirection: 'column',
  },
  modalDetail: {
    paddingBottom: '20px',
    alignContent: 'center',
    justifyContent: 'space-around',
  },
  btnContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  modalRoot: {
    '& div.MuiPaper-root': {
      background: palette.background.default,
      width: '630px',
    },
  },
  fullFlex: {
    paddingBottom: spacing(1),
    paddingRight: spacing(0),
    flexBasis: '100%',
    width: '80%',
  },
  rowContainer: {
    display: 'flex',
    alignContent: 'center',
  },
}));
