import { modelProtection } from '@wings-shared/core';
import { IContext, IContextChange } from '../Interfaces';

@modelProtection
export class LogContextModel{
  changes: LogContextChangeModel[];
  exceptionDetails: string;

  constructor(data?: Partial<LogContextModel>) {
    Object.assign(this, data);
  }

  static deserialize(log: IContext): LogContextModel {
    if (!log) {
      return new LogContextModel();
    }

    const data: Partial<LogContextModel> = {
      changes: LogContextChangeModel.deserializeList(log.Changes),
      exceptionDetails: log.ExceptionDetails
    };
    return new LogContextModel(data);
  }

  static deserializeList(log: IContext[]): LogContextModel[] {
    return log
      ? log
        .map((logs: IContext) =>
          LogContextModel.deserialize(logs))
      : [];
  }
}

export class LogContextChangeModel {
  property: string;
  oldValue: string;
  newValue: string;

  constructor(data?: Partial<LogContextChangeModel>) {
    Object.assign(this, data);
  }

  static deserialize(log: IContextChange): LogContextChangeModel {
    if (!log) {
      return new LogContextChangeModel();
    }

    const data: Partial<LogContextChangeModel> = {
      property: log.Property,
      oldValue: log.OldValue,
      newValue: log.NewValue
    };
    return new LogContextChangeModel(data);
  }

  static deserializeList(log: IContextChange[]): LogContextChangeModel[] {
    return log
      ? log
        .map((logs: IContextChange) =>
          LogContextChangeModel.deserialize(logs))
      : [];
  }
}