import { regex } from '@wings-shared/core';

export const fields = {
  firstName: {
    label: 'First Name',
  },
  lastName: {
    label: 'Last Name',
  },
  username: {
    label: 'User Name',
  },
};