import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const styles = makeStyles(({ palette }) =>({
  headerContainerTop: {
    border: '1px solid #ddd',
    padding: '25px 25px',
    height: 'calc(100vh - 200px)',
  },
  flexSection: {
    display: 'flex',
  },
  selectionSection: {
    width: '85%',
  },
  selectInput: {
    width: 200,
    marginRight: 10,
    float: 'left',
    height: 80,
    '& div.MuiSelect-select': {
      height: 40,
      paddingTop: 9,
      color: palette.grey.A700,
      fontSize: 12,
    },
    '& input': {
      height: 40,
      fontSize: 12,
      color: palette.grey.A700,
    },
  },
  manageRoleBtn: {
    width: '15%',
    textAlign: 'right',
    '& button': {
      backgroundColor: '#1976D2',
      height: 40,
      marginTop: 18,
      width: 127,
      '&:hover': {
        backgroundColor: '#63A4FF',
      },
    },
  },
  subTitleRes:{
    fontSize: 14,
    color: palette.grey.A700,
    marginTop: 1,
  },
  textWarningMessages: {
    margin: '1px 30px 0 10px',
    fontSize: 14,
    fontWeight: 600,
    color: palette.error.light,
  },
  userGoodMessages: {
    display: 'flex',
    alignItems: 'center',
    color: palette.grey.A700,
    border: '1px solid #65a61b',
    borderRadius: 5,
    padding: '6px 10px',
    background: '#EEF0E9',
    '& svg': {
      fontSize: '1.10rem',
      color: '#65A61B',
    },
  },
  textGoodMessages: {
    margin: '1px 30px 0 10px',
    fontWeight: 600,
    fontSize: 14,
    color: '#65A61B',
  },
  userWarningMessages: {
    display: 'flex',
    alignItems: 'center',
    color: palette.grey.A700,
    border: '1px solid #db063b',
    borderRadius: 5,
    padding: '6px 10px',
    background: '#F3E8EB',
    '& svg': {
      fontSize: '1.10rem',
      color: palette.error.light,
    },
  },
  textInput: {
    '& input': {
      height: 40,
    },
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: palette.grey.A700,
  },
  resultSection: {
    color: palette.grey.A700,
    fontSize: 18,
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 10,
  },
}));
