import { regex } from '@wings-shared/core';

export const fields = {
  appService: {
    label: 'App Service',
    rules: 'required',
  },
  roles: {
    label: 'Roles',
    rules: 'required',
  },
  customer: {
    label: 'Customer',
  },
  sites: {
    label: 'Sites',
  },
};
  