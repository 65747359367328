import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  modalRoot: {
    '& div.MuiPaper-root': {
      height: 500,
      width: 800,
      padding: 20,
    }
  },
  btnAlign: {
    backgroundColor: '#1976D2',
    textTransform: 'capitalize',
    height: 40,
    width: '100%',
    boxShadow: 'none',
    marginTop: 20,
  },
  headerWrapper: {
    '& div': {
      display: 'none',
    },
  },
}));
