import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const styles = makeStyles((theme: Theme) =>({
  title: {
    fontSize: 18,
    margin: 100,
    display: 'flex',
    justifyContent: 'center',
  },
  userTab: {
    background: theme.palette.background.paper,
    padding: 15,
    marginTop: 10,
    '& div.MuiTabPanel-root': {
      padding: 0,
    },
    '& span.MuiTabs-indicator': {
      padding: 0,
      borderTop: '65px solid #1976D2',
      width: '3px !important',
      transition: 'none',
    },
    '& button.MuiTab-textColorPrimary': {
      padding: '0 15px',
      width: 200,
      height: 63,
      background: '#F2F2F2',
      margin: 0,
      borderTop: '1px solid #ddd',
      borderRight: 0,
      fontWeight: 600,
      '& span': {
        fontSize: 14,
      },
      '&:first-child': {
        borderLeft: '1px solid #ddd',
      },
      '&:last-child': {
        borderRight: '1px solid #ddd',
      },
    },
    '& button.Mui-selected': {
      background: theme.palette.background.paper,
      borderBottom: 0,
      color: '#1976D2',
    },
    '& div.MuiTabs-root': {
      border: 0,
    },
  },
}));
