import { IAPIUserRoleResponse } from '../Interfaces';
import { UserCSDDetails } from './UserCSDDetails.model';
import { IdNameModel, ISelectOption, modelProtection, Utilities } from '@wings-shared/core';

@modelProtection
export class UserRolesModel extends IdNameModel {
  serviceName: string;
  permissions: string[] = [];
  level: ISelectOption;
  csdDetails: UserCSDDetails = null;

  constructor(data?: Partial<UserRolesModel>) {
    super();
    Object.assign(this, data);
    this.csdDetails = new UserCSDDetails(data?.csdDetails);
  }

  static deserialize(role: IAPIUserRoleResponse): UserRolesModel {
    if (!role) {
      return new UserRolesModel();
    }
    const data: Partial<UserRolesModel> = {
      id: Utilities.getTempId(true),
      name: role.Name,
      serviceName: role.ServiceName,
      level: { label: role.Level, value: role.Level },
      permissions: role.Permissions?.filter(Boolean),
      csdDetails: UserCSDDetails.deserialize(role.CSDDetails),
    };

    return new UserRolesModel(data);
  }

  public serialize(): IAPIUserRoleResponse {
    return {
      Name: this.name,
      ServiceName: this.serviceName,
      Level: this.level?.value as string,
      Permissions: this.permissions,
      CSDDetails: this.csdDetails ? this.csdDetails.serialize() : null,
    };
  }

  static deserializeList(roles: IAPIUserRoleResponse[]): UserRolesModel[] {
    return roles
      ? roles.map((role: IAPIUserRoleResponse) => UserRolesModel.deserialize(role))
      : [];
  }
}