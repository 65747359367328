import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(({ palette }) => ({
  inputBox: {
    flexBasis: '50%',
    whiteSpace: 'nowrap',
    paddingRight: 24,
    textOverflow: 'ellipsis',
    paddingBottom: 24,
  },
  singleContent: {
    backgroundColor: palette.background.paper,
    padding: 15,
    marginTop: 5,
    display: 'flex',
    flexWrap: 'wrap',
    '& h6': {
      fontSize: 16,
      color: '#929292',
    },
    '& button': {
      marginLeft: 0,
      marginTop: 10,
    },
  },
}));
