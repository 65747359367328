import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(({ palette }) =>({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.background.paper,
    padding: 15,
    justifyContent: 'space-between',
  },
  mainroot: {
    display: 'flex',
    height: '100%',
    width: '100%',
    '& div.ag-cell': {
      color: palette.grey.A700,
      '&.navigateText': {
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '& div.ag-header-row': {
      background: '#5F5F5F',
    },
    '& div.ag-header-viewport': {
      background: '#5F5F5F',
    },
    '& div.ag-row-odd': {
      background: 'transparent',
    },
    '& div.MuiChip-colorPrimary': {
      background: '#1976D2',
    },
  },
  mainContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  subSection: {
    display: 'flex',
    alignItems: 'center',
  },
  flexSection: {
    display: 'flex',
  },
  spaceSection:{
    marginRight: 10,
  },
  heading: {
    fontSize: 22,
  },
  icon: {
    width: 30,
    marginRight: 10,
    fontSize: 30,
  },
  filterBtn: {
    padding: '0px 0px 0px 10px',
    minWidth: 40,
    marginRight: 7,
    position: 'relative',
    height: 30,
    marginLeft: 0,
  },
}));
