import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(({ palette }) =>({
  content: {
    backgroundColor: palette.background.paper,
    padding: 15,
    marginTop: 5,
  },
  usernameInput: {
    width: 200,
    marginRight: 15,
  },
  btnSubmit: { 
    marginTop: 22,
  },
  customToolTip: {
    border: `1px solid ${palette.background.default}`,
    background: palette.background.paper,
  },
  customArrow: {
    '&::before': {
      border: `1px solid ${palette.background.default}`,
    },
    color: palette.background.paper,
  },
  overwriteBox:{
    '& span.MuiFormControlLabel-label':{
      fontSize: 14,
      fontWeight: 600,
    }
  },
  warningMessageContainer: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #1976d2',
    borderRadius: 5,
    padding: '6px 10px',
    background: '#E6EBF0',
  },
  warningMessage: {
    margin: '1px 30px 0 10px',
    fontSize: 13,
    color: palette.grey.A700,
  },
  cardRowBtn: {
    display: 'flex',
    justifyContent: 'end',
    paddingRight: 0,
  },
  btnContainer: { display: 'flex',
  },
  btnAlign: {
    backgroundColor: '#1976D2',
    textTransform: 'capitalize',
    height: 40,
    width: 150,
    marginLeft: 50,
  },
  btnSection: {
    '& button': {
      border: 0,
      paddingLeft: 0,
      color: '#1976d2',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      textTransform: 'capitalize',
      '&:hover': {
        color: '#1976d2',
        backgroundColor: 'transparent !important',
        textDecoration: 'underline',
        boxShadow: 'none',
      },
    },
  },
}));
